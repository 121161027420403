import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import i1 from "./assets/images/1.gif";
import newbanner from "./assets/images/newbanner.png"

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: solid black 2px;
  margin-left: 10px;
  background-color: #ffffff;
  padding: 10px;
  font-weight: bold;
  color: #000000;
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledImg = styled.img`
  width: 200px;
  height: 200px;
  @media (min-width: 767px) {
    width: 350px;
    height: 350px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("");
  const [claimingNft, setClaimingNft] = useState(false);

  const claimNFTs = (_amount) => {
    if (_amount <= 0) {
      return;
    }
    setFeedback("Launching your Doodlenaut(s) into the Ether (minting)...");
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(blockchain.account, _amount)
      .send({
        gasLimit: 285000 * _amount,
        to: "0x5EaB5A8f381675Bd42BCD714ec2F1E1918096640",
        from: blockchain.account,
        value: blockchain.web3.utils.toWei((.01 * _amount).toString(), "ether"),
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong! Please try again later. To see what went wrong, check your Metamask transaction.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        setFeedback(
          "CONGRATS! Your Doodlenaut(s) had a successful launch! Go visit OpenSea.io to view them."
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };


  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen style={{ backgroundColor: "var(--black)" }}>
      <s.Container flex={1} ai={"center"} style={{ padding: 24 }}>
        <s.TextTitle
          style={{ textAlign: "center", fontSize: 28, fontWeight: "bold", padding: 0, margin: 0, 
          borderStyle: "solid", borderColor: "black", borderWidth: 0,
          borderRadius: 50  }}
        >
          {/* Doodlenaut Minter! */}
          <a href="https://doodlenauts.io"><StyledImg alt={"example"} 
          src={newbanner} 
          style={{width: 486/1.5, height: 207/1.5}}></StyledImg></a>
          
        </s.TextTitle>
        <s.SpacerMedium />
        <ResponsiveWrapper flex={1} style={{ padding: 24, paddingTop: 0 }}>
          <s.Container flex={1} jc={"center"} ai={"center"} style={{paddingTop: 0}}>
            <StyledImg alt={"example"} src={i1} style={{paddingTop: 0, borderStyle: "solid", borderColor: "black", borderWidth: 5,
                    borderRadius: 20 }}/>
            <s.SpacerMedium />
            <s.TextTitle
              style={{ textAlign: "center", fontSize: 35, fontWeight: "bold", borderStyle: "solid", borderColor: "black", 
              borderWidth: 5,
              paddingLeft: 100,
              paddingRight: 100,
              borderRadius: 50  }}
            >
              {(blockchain.account == null ? "????" : (data.totalSupply))}/333
            </s.TextTitle>
          </s.Container>
          <s.SpacerMedium />
          <s.Container
            flex={1}
            jc={"center"}
            ai={"center"}
            style={{ backgroundColor: "#c2c2c2", padding: 24, borderStyle: "solid", borderColor: "black", borderWidth: 5,
                    borderRadius: 30 }}
          >
            {Number(data.totalSupply) == 333 ? (
              <>
                <s.TextTitle style={{ textAlign: "center" }}>
                  The sale has ended.
                </s.TextTitle>
                <s.SpacerSmall />
                <s.TextDescription style={{ textAlign: "center" }}>
                  Dont worry, you're not missing out! You can still Doodlenauts on{" "}
                  <a
                    // target={"_blank"}
                    href={"https://opensea.io/collection/doodlenauts"}
                  >
                    Opensea.io
                  </a>
                </s.TextDescription>
              </>
            ) : (
              <>
              <s.TextTitle style={{fontSize: 25, textDecoration: "underline", textAlign: "center"}}>PRE-MINTING IS NOW LIVE!</s.TextTitle>
                <s.TextTitle style={{ textAlign: "center" }}>
                  1 Doodlenaut only costs 0.01 ETH!
                </s.TextTitle>
                <s.TextDescription style={{ textAlign: "center" }}>
                  *Excluding gas fees*
                </s.TextDescription>
                <s.SpacerMedium/>
                <s.TextDescription style={{textAlign: "center"}}>
                      You can purchase a maximum of 33 Doodlenauts at once!
                    </s.TextDescription>
                <s.TextDescription style={{textAlign: "center"}}>
                      To learn more, visit our <a href="https://etherscan.io/address/0x5eab5a8f381675bd42bcd714ec2f1e1918096640">Smart Contract</a>
                    </s.TextDescription>
                    <s.TextDescription style={{textAlign: "center"}}>
                      or the <a href="https://doodlenauts.io">Doodlenauts Main Page</a>!
                    </s.TextDescription>
                    <s.SpacerMedium/>
                    <s.TextDescription style={{ textAlign: "center" }}>*During pre-mint, only 333 of 3333 Doodlenauts</s.TextDescription>
                    <s.TextDescription style={{ textAlign: "center" }}> are available.</s.TextDescription>
                <s.SpacerLarge />
                <s.TextDescription style={{ textAlign: "center" }}>
                  {feedback}
                </s.TextDescription>
                <s.SpacerMedium />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription style={{ textAlign: "center" }}>
                      Connect to the Ethereum network (Ethereum Mainnet)
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    <s.SpacerLarge />
                    <s.SpacerSmall />
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: "center" }}>
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <s.Container ai={"center"} jc={"center"} fd={"row"}>
                    I'll take <select 
                    class="bruhbutton"
                    id="bruh" 
                    type="select" 
                    min="1" 
                    max="20" 
                    placeholder="# of Doodlenauts"
                    style={{
                      fontFamily: "monospace",
                      fontWeight: "bold",
                      color: "#000000",
                      border: "solid black 2px",
                      borderRadius: "2px",
                      marginLeft: "10px",
                      fontSize: "15px"
                      }}>
                        <option value="1"> 1</option>
                        <option value="2"> 2</option>
                        <option value="3"> 3</option>
                        <option value="4"> 4</option>
                        <option value="5"> 5</option>
                        <option value="6"> 6</option>
                        <option value="7"> 7</option>
                        <option value="8"> 8</option>
                        <option value="9"> 9</option>
                        <option value="10"> 10</option>
                        <option value="11"> 11</option>
                        <option value="12"> 12</option>
                        <option value="13"> 13</option>
                        <option value="14"> 14</option>
                        <option value="15"> 15</option>
                        <option value="16"> 16</option>
                        <option value="17"> 17</option>
                        <option value="18"> 18</option>
                        <option value="19"> 19</option>
                        <option value="20"> 20</option>
                        <option value="21"> 21</option>
                        <option value="22"> 22</option>
                        <option value="23"> 23</option>
                        <option value="24"> 24</option>
                        <option value="25"> 25</option>
                        <option value="26"> 26</option>
                        <option value="27"> 27</option>
                        <option value="28"> 28</option>
                        <option value="29"> 29</option>
                        <option value="30"> 30</option>
                        <option value="31"> 31</option>
                        <option value="32"> 32</option>
                        <option value="33"> 33</option>
                        </select>!
                    <StyledButton
                      disabled={claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs(document.getElementById("bruh").value);
                        getData();
                      }}
                    >
                      
                      {claimingNft ? "BUSY" : "PURCHASE"}
                    </StyledButton>
                  </s.Container>
                )}
              </>
            )}
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerSmall />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription style={{ textAlign: "center", fontSize: 9 }}>
            Please make sure you are connected to the right network (Ethereum
            Mainnet) and the correct address. Please note: Once you make the
            purchase, you cannot undo this action.
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center", fontSize: 9 }}>
            We have set the gas limit to 285000 per Doodlenaut for the contract to successfully
            mint your NFT. We recommend that you don't change the gas limit.
          </s.TextDescription>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
